import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment TeamCard on WpTeam {
      id
      title
      uri
      acf {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
        bio
        position
        email
        phone
      }
      boxData {
        id
      }
    }
    fragment PropertyCard on WpProperty {
      id
      uri
      title
      date
      agents {
        nodes {
          title
        }
      }
      images {
        url
        index
      }
      boxData {
        display_price
        displayAddress
        website_status
        status
        suburb_undisclosed
        hidden
        price_undisclosed
        price_to
        price_from
        sale_date
        under_offer
        property {
          unit
          baths
          beds
          cars
          garages
          number
          street_name
          street_type
          suburb
          state
          postcode
        }
      }
    }
    fragment RentalCard on WpRental {
      id
      uri
      title
      date
      agents {
        nodes {
          title
        }
      }
      images {
        url
        index
      }
      boxData {
        advertisable
        display_price
        displayAddress
        website_status
        status
        suburb_undisclosed
        hidden
        price_undisclosed
        price_to
        price_from
        property {
          unit
          baths
          beds
          cars
          garages
          number
          street_name
          street_type
          suburb
          state
          postcode
        }
      }
    }
    fragment ProjectCard on WpProject {
      id
      uri
      title
      acf {
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1500
              )
            }
          }
        }
        address
        suburb
        meta {
          bed
          bath
          car
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
}

export default useLayoutQuery
