import React from 'react'

export const Logo = ({ color = '#FFFFFF' }) => (
  <svg width="228" height="16" viewBox="0 0 228 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.521729V15.4918H2.46582V9.2119H8.2805V7.1616H2.46582V2.74336H9.49283V0.521729H0Z" fill={color}/>
    <path d="M21.6986 14.9855C20.4919 14.3097 19.5583 13.3683 18.8941 12.1632C18.23 10.958 17.897 9.57383 17.897 8.00868C17.897 6.44352 18.23 5.08051 18.8941 3.87536C19.5583 2.67021 20.4919 1.72496 21.6986 1.04152C22.9053 0.356167 24.2748 0.0134888 25.8089 0.0134888C27.343 0.0134888 28.7331 0.356167 29.9398 1.0396C31.1446 1.72303 32.0801 2.66828 32.7443 3.87343C33.4084 5.07858 33.7414 6.457 33.7414 8.00675C33.7414 9.5565 33.4084 10.9561 32.7443 12.1612C32.0801 13.3664 31.1446 14.3078 29.9398 14.9835C28.7331 15.6593 27.3561 15.9981 25.8089 15.9981C24.2617 15.9981 22.9053 15.6593 21.7005 14.9835M28.6339 13.0487C29.4627 12.5636 30.1101 11.8802 30.5759 10.9984C31.0417 10.1186 31.2737 9.11949 31.2737 8.00675C31.2737 6.89401 31.0417 5.8987 30.5759 5.0266C30.1101 4.15258 29.4627 3.46722 28.6339 2.97631C27.8051 2.48154 26.8641 2.23705 25.8089 2.23705C24.7537 2.23705 23.8333 2.48347 23.0045 2.97631C22.1757 3.46915 21.5283 4.15451 21.0625 5.0266C20.5966 5.90063 20.3628 6.89401 20.3628 8.00675C20.3628 9.11949 20.5948 10.1186 21.0625 10.9984C21.5283 11.8802 22.1757 12.5636 23.0045 13.0487C23.8333 13.5358 24.7687 13.7784 25.8089 13.7784C26.8491 13.7784 27.8051 13.5358 28.6339 13.0487Z" fill={color}/>
    <path d="M43.3555 0.521729V15.4918H52.6631V13.2932H45.8213V0.521729H43.3555Z" fill={color}/>
    <path d="M61.8286 0.521729V15.4918H71.1362V13.2932H64.2944V0.521729H61.8286Z" fill={color}/>
    <path d="M80.3018 0.521729V15.4918H90.5542V13.3356H82.6834V8.81147H89.5271V6.8016H82.6834V2.67983H90.3502V0.521729H80.3018Z" fill={color}/>
    <path d="M98.9995 0.521729V2.74336H103.665V15.4918H106.129V2.74336H110.773V0.521729H98.9995Z" fill={color}/>
    <path d="M119.568 0.521729V2.74336H124.232V15.4918H126.698V2.74336H131.343V0.521729H119.568Z" fill={color}/>
    <path d="M215.959 14.9854C214.752 14.3097 213.819 13.3683 213.154 12.1632C212.49 10.958 212.157 9.57383 212.157 8.00867C212.157 6.44352 212.49 5.08051 213.154 3.87536C213.819 2.67021 214.752 1.72495 215.959 1.04152C217.164 0.35809 218.535 0.0154114 220.067 0.0154114C221.6 0.0154114 222.993 0.35809 224.198 1.04152C225.403 1.72495 226.338 2.67021 227.003 3.87536C227.667 5.08051 228 6.45892 228 8.00867C228 9.55842 227.667 10.958 227.003 12.1632C226.338 13.3683 225.403 14.3097 224.198 14.9854C222.993 15.6612 221.615 16 220.067 16C218.52 16 217.164 15.6612 215.959 14.9854ZM222.894 13.0507C223.723 12.5655 224.37 11.8821 224.836 11.0004C225.302 10.1206 225.534 9.12141 225.534 8.00867C225.534 6.89593 225.302 5.90062 224.836 5.02853C224.37 4.1545 223.723 3.46915 222.894 2.97823C222.065 2.48347 221.124 2.23897 220.067 2.23897C219.01 2.23897 218.092 2.48539 217.263 2.97823C216.434 3.47107 215.787 4.15643 215.321 5.02853C214.855 5.90062 214.623 6.89593 214.623 8.00867C214.623 9.12141 214.855 10.1206 215.321 11.0004C215.787 11.8821 216.434 12.5655 217.263 13.0507C218.092 13.5377 219.027 13.7803 220.067 13.7803C221.108 13.7803 222.065 13.5377 222.894 13.0507Z" fill={color}/>
    <path d="M203.106 12.1632C202.442 13.3683 201.507 14.3097 200.302 14.9854C199.095 15.6612 197.718 16 196.171 16C194.624 16 193.267 15.6612 192.062 14.9854C190.856 14.3097 189.922 13.3683 189.256 12.1632C188.592 10.958 188.261 9.57382 188.261 8.00867C188.261 6.44352 188.594 5.0805 189.256 3.87535C189.92 2.6702 190.856 1.72688 192.062 1.04344C193.267 0.360012 194.637 0.017334 196.171 0.017334C197.705 0.017334 199.095 0.360012 200.302 1.04344C201.507 1.72688 202.442 2.6702 203.106 3.87535L200.94 5.02852C200.474 4.1545 199.827 3.47107 198.998 2.97823C198.169 2.48346 197.226 2.23704 196.171 2.23704C195.116 2.23704 194.195 2.48346 193.366 2.97823C192.538 3.47107 191.89 4.1545 191.424 5.02852C190.959 5.90255 190.725 6.89593 190.725 8.0106C190.725 9.12526 190.957 10.1225 191.424 11.0023C191.89 11.8821 192.536 12.5655 193.366 13.0526C194.195 13.5377 195.131 13.7822 196.171 13.7822C197.211 13.7822 198.167 13.5377 198.998 13.0526C199.827 12.5655 200.474 11.8821 200.94 11.0023L203.106 12.1651V12.1632Z" fill={color}/>
    <path d="M161.025 13.6321C161.571 13.4049 162.085 13.0776 162.568 12.6502L158.505 8.78835C157.814 9.05787 157.289 9.41018 156.929 9.84526C156.57 10.2784 156.391 10.8444 156.391 11.5413C156.391 12.0111 156.518 12.4269 156.774 12.7908C157.03 13.1527 157.382 13.4415 157.831 13.6552C158.28 13.8688 158.789 13.9747 159.356 13.9747C159.923 13.9747 160.478 13.8611 161.025 13.6321ZM156.484 15.4456C155.73 15.0759 155.139 14.56 154.71 13.8996C154.282 13.2374 154.067 12.4808 154.067 11.628C154.067 10.546 154.333 9.6797 154.866 9.02515C155.399 8.37059 156.134 7.81615 157.073 7.36181C156.671 6.86319 156.351 6.33377 156.11 5.77355C155.867 5.2114 155.754 4.63193 155.768 4.03513C155.768 3.26699 155.955 2.57779 156.327 1.96559C156.699 1.35338 157.212 0.87402 157.861 0.525566C158.51 0.177112 159.229 0.00192261 160.016 0.00192261C160.845 0.00192261 161.588 0.152085 162.245 0.450485C162.901 0.748884 163.419 1.18975 163.799 1.77307C164.179 2.35639 164.37 3.061 164.37 3.88497C164.37 4.70894 164.183 5.35772 163.81 5.91216C163.438 6.46661 162.95 6.91517 162.349 7.25592C161.747 7.59668 161.004 7.92395 160.119 8.23775L158.813 6.85164C159.573 6.611 160.168 6.3973 160.596 6.21056C161.025 6.02575 161.397 5.76007 161.715 5.41162C162.033 5.06317 162.192 4.61845 162.192 4.07941C162.192 3.70978 162.099 3.36903 161.913 3.05523C161.726 2.74335 161.468 2.495 161.135 2.30826C160.804 2.12345 160.445 2.03104 160.057 2.03104C159.477 2.03104 158.989 2.22356 158.596 2.60666C158.202 2.98977 158.005 3.49609 158.005 4.12176C158.005 4.60498 158.129 5.08049 158.377 5.55023C158.626 6.01997 158.991 6.47431 159.476 6.91517L164.037 11.2237C164.755 10.342 165.322 9.30429 165.737 8.10877L167.292 9.55841C166.794 10.6962 166.186 11.6857 165.468 12.5232L168.641 15.4879H165.614L163.998 13.9728C163.251 14.6274 162.456 15.1279 161.612 15.4764C160.768 15.8248 159.919 15.9981 159.062 15.9981C158.095 15.9981 157.234 15.8133 156.482 15.4436" fill={color}/>
  </svg>
)

export const Brand = ({ color = '#42403D' }) => (
  <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0751 7.99115H13.7193V2.67224H22.6072V0H10.6007V7.98262H3.11861H0V26H3.11861H12.0065V23.3278H3.11861V10.6549H10.6007V18.0152H13.7193V10.657H21.0751V7.99115Z" fill={color}/>
    <path d="M25.9954 0V2.67224H34.8833V15.343H27.3991V7.98262H24.2805V15.343H16.9269V18.0089H24.2805V23.3278H15.3926V26H27.3991V18.0152H34.8833H37.9998V0H25.9954Z" fill={color}/>
  </svg>
)


export const Search = ({ color = '#FFFFFF' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.286 0.00112915C4.1656 0.00112915 0 4.16673 0 9.28713C0 14.4075 4.1656 18.5731 9.286 18.5731C11.5892 18.5731 13.6978 17.7278 15.322 16.3341L18.7806 19.7927C18.9103 19.9224 19.0884 20.0013 19.2853 20.0013C19.6798 20.0013 19.9993 19.681 19.9993 19.2873C19.9993 19.0896 19.9197 18.9107 19.79 18.7818L16.3306 15.3232C17.7251 13.699 18.5704 11.5904 18.5704 9.28721C18.5704 4.16681 14.4048 0.00120915 9.28436 0.00120915L9.286 0.00112915ZM9.286 1.42925C13.6336 1.42925 17.143 4.93865 17.143 9.28625C17.143 13.6338 13.6336 17.1432 9.286 17.1432C4.9384 17.1432 1.429 13.6338 1.429 9.28625C1.429 4.93865 4.9384 1.42925 9.286 1.42925Z" fill={color} />
  </svg>
)

export const Chevron = ({ color = '#D0CFC6' }) => (
  <svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.55179 7C7.45146 7 7.30097 6.94984 7.20065 6.89968L0.177896 0.880172C-0.0227538 0.679522 -0.0729164 0.378546 0.127734 0.177896C0.328384 -0.0227538 0.629359 -0.0729164 0.830009 0.127734L7.55179 5.84626L14.2233 0.127734C14.424 -0.0729164 14.7249 -0.0227538 14.9256 0.177896C15.1262 0.378546 15.0761 0.729684 14.8754 0.880172L7.85268 6.89968C7.80252 6.94984 7.65203 7 7.55171 7H7.55179Z" fill={color} />
  </svg>
)

export const Cross = ({ color = '#FFFFFF' }) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7075 1.55277L12.4136 10L21.7075 18.4472C22.0975 18.8018 22.0975 19.3779 21.7075 19.7341C21.3157 20.0886 20.6819 20.0886 20.292 19.7341L11 11.2851L1.70805 19.7341C1.31806 20.0886 0.684345 20.0886 0.292488 19.7341C-0.0974961 19.3779 -0.0974961 18.8018 0.292488 18.4472L9.58636 10L0.292488 1.55277C-0.0974961 1.19824 -0.0974961 0.622132 0.292488 0.265899C0.68435 -0.0886329 1.31806 -0.0886329 1.70805 0.265899L11 8.71487L20.292 0.265899C20.6819 -0.0886329 21.3157 -0.0886329 21.7075 0.265899C22.0975 0.622136 22.0975 1.19824 21.7075 1.55277Z" fill={color} />
  </svg>
)